import React from "react";
import styled from "styled-components";

// Components
import { CollectionInformation } from "./collection-information";
import { ShareButtons } from "../buttons/share-buttons";

// Product
const ProductInfoContainer = styled.div`
	display: ${(props) => props.displayOnDesktop};
	order: 1;

	@media (max-width: 900px) {
		display: ${(props) => props.displayOnMobile};
	}
`;

const TitleShareContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	width: 100%;

	& h1 {
		font-size: 16px;
		line-height: 125%;

		width: calc(100% - 90px);
	}
`;

const Price = styled.p`
	width: 100%;

	margin: 0 0 1em 0;

	& span {
		padding: 0 0 0 10px;
	}
`;

export const ProductInfo = ({
	product,
	showOnDesktop,
	showOnMobile,
	collection,
	location,
	price,
}) => (
	<ProductInfoContainer
		displayOnDesktop={showOnDesktop === true ? `block` : `none`}
		displayOnMobile={showOnMobile === true ? `block` : `none`}
	>
		{collection !== null && <CollectionInformation collection={collection} />}
		<TitleShareContainer>
			<h1 className="caps">{product.title}</h1>
			<div className="share-container">
				<ShareButtons
					pageType={`product`}
					title={product.title}
					location={location.href}
					image={product.images.length >= 1 && product.images[0].originalSrc}
				/>
			</div>
		</TitleShareContainer>

		<Price>
			{new Intl.NumberFormat("en-GB", {
				style: "currency",
				currency: "GBP",
			}).format(price)}
			{""}
			<span className="caps small-text">INC VAT</span>
		</Price>
	</ProductInfoContainer>
);
