import React from "react";
import styled from "styled-components";

const QuantityContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	font-feature-settings: "tnum";

	& button {
		margin: 0 10px;
	}
`;

export const QuantityButton = ({ quantity, setQuantity }) => {
	const increaseQuantity = () => {
		setQuantity((q) => q + 1);
	};
	const decreaseQuantity = () => {
		setQuantity((q) => (q <= 1 ? 1 : q - 1));
	};
	return (
		<QuantityContainer>
			<label className="label" htmlFor="quantity">
				Quantity{" "}
			</label>

			<div className="control">
				<button className="button" onClick={decreaseQuantity}>
					-
				</button>
			</div>
			<div className="control">
				<span>{quantity}</span>
			</div>
			<div className="control">
				<button className="button" onClick={increaseQuantity}>
					+
				</button>
			</div>
		</QuantityContainer>
	);
};
