import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import Modal from "react-modal";

// Components
import { LightboxGallery } from "../images/lightbox-gallery";

// Styles
import { secondaryDark } from "../utils/colors";

const LightboxContainer = styled.div`
	background-color: #fff;
	z-index: 100;

	& > div {
		background-color: #fff;

		overflow: hidden;

		& .lightbox-wrapper {
			position: relative;
			width: 100%;
			height: 100%;
		}
	}
`;

const CloseLightbox = styled.button`
	position: absolute;
	top: 25px;
	right: 25px;

	color: ${secondaryDark};

	z-index: 100;

	line-height: 1;
	font-size: 20px;

	padding: 10px;
`;

Modal.setAppElement("#___gatsby");

export const Lightbox = ({
	images,
	title,
	isLightboxOpen,
	setLightBoxOpen,
}) => {
	useEffect(() => {
		if (isLightboxOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "";
		}
	}, [isLightboxOpen]);

	useEffect(() => {
		if (typeof window !== undefined) {
			document.addEventListener("keydown", (event) => onEscape(event));
		}
	}, [isLightboxOpen]);

	const onEscape = (event) => {
		if (event.keyCode === 27 && isLightboxOpen === true) {
			document.body.style.overflow = "";
			setLightBoxOpen(false);
		}
	};

	return (
		<Modal
			isOpen={isLightboxOpen}
			className="product-lighbox-modal"
			contentLabel={`${title} Gallery`}
		>
			<LightboxContainer>
				<Div100vh>
					<div className="lightbox-wrapper">
						<CloseLightbox onClick={() => setLightBoxOpen(false)}>
							✕
						</CloseLightbox>
						<LightboxGallery images={images} title={title} />
					</div>
				</Div100vh>
			</LightboxContainer>
		</Modal>
	);
};
