import React, { useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;

	& .zoom-image {
		background-size: 150%;
		background-position: center;
		background-repeat: no-repeat;

		cursor: crosshair;
		transition: 100ms all ease;

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
`;

const HiddenImage = styled.img`
	display: none;

	object-position: center;
	object-fit: cover;
`;

export const ContentZoom = ({ largeImageUrl, altText }) => {
	const [topOffset, setTopOffset] = useState(20);
	const [leftOffset, setLeftOffset] = useState(50);
	const [imageLoaded, setImageLoaded] = useState(false);
	const ref = useRef(null);

	const handleMouseMove = (e) => {
		if (ref !== null && ref !== undefined) {
			// getBoundingClientReact gives us various information about the position of the element.
			let dimensions = ref.current.getBoundingClientRect();

			// Calculate the position of the cursor inside the element (in pixels).
			let x = e.clientX - dimensions.left;
			let y = e.clientY - dimensions.top;

			// Calculate the position of the cursor as a percentage of the total size of the element.
			let xpercent = Math.round(100 / (dimensions.width / x));
			let ypercent = Math.round(100 / (dimensions.height / y));

			window.requestAnimationFrame(() => {
				setLeftOffset(xpercent);
				setTopOffset(ypercent);
			});
		}
	};

	return (
		<>
			<Container
				onMouseMove={(e) => handleMouseMove(e)}
				// onTouchMove={(e) => handleMouseMove(e)}
				onMouseLeave={() => {
					window.requestAnimationFrame(() => {
						setLeftOffset(50);
						setTopOffset(0);
					});
				}}
				ref={ref}
			>
				<div
					className="zoom-image"
					style={{
						backgroundImage: `url(${largeImageUrl})`,
						backgroundPositionX: `${leftOffset}%`,
						backgroundPositionY: `${topOffset}%`,
					}}
				/>
			</Container>
			<HiddenImage
				src={largeImageUrl}
				alt={altText}
				onLoad={() => setImageLoaded(true)}
			/>
		</>
	);
};
