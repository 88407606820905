import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Components
import { ContentContainer } from "../containers/container";
import { FlexContainer } from "../containers/flex-container";
import { ProductEnquiry } from "./product-enquiry";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { TechSpecs } from "./tech-specs";
import { ShippingPopup } from "./shipping-popup";

// Styles
import { secondaryLight, secondaryDark } from "../utils/colors";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

const Flex = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	height: 100%;
	margin: 0 -15px;
`;

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-self: ${(props) => props.alignSelf};
	// align-content: ${(props) => props.alignContent};

	margin: ${(props) => (props.margin ? props.margin : `0 -15px`)};

	width: ${(props) => (props.width ? props.width : `100%`)};
`;

const FlexCol = styled.div`
	flex: ${(props) => (props.flex ? props.flex : `1`)};
	max-width: ${(props) => props.desktopMaxWidth};

	padding: 0 15px;

	@media (max-width: 900px) {
		flex: ${(props) => (props.tabletFlex ? props.tabletFlex : `100%`)};
		width: ${(props) => (props.tabletFlex ? props.tabletFlex : `100%`)};
		max-width: ${(props) => (props.tabletFlex ? props.tabletFlex : `100%`)};
		order: ${(props) => (props.mobileOrder ? props.mobileOrder : 1)};
	}
	@media (max-width: 767px) {
		flex: ${(props) => (props.mobileFlex ? props.mobileFlex : `100%`)};
		width: ${(props) => (props.mobileFlex ? props.mobileFlex : `100%`)};
		max-width: ${(props) => (props.mobileFlex ? props.mobileFlex : `100%`)};
	}
`;

const Swatch = styled.div`
	flex: 50%;
	max-width: 50%;

	padding: 0 15px;
	margin: 0 0 30px 0;

	cursor: pointer;

	transition: 150ms opacity ease;

	&:hover {
		opacity: 0.75;
	}

	& img {
		width: 100%;
		height: 100%;

		object-fit: cover;
	}

	// @media (max-width: 767px) {
	// 	flex: 25%;
	// 	max-width: 25%;
	// }
`;

const SwatchTitle = styled.p`
	margin: 0 0 1em 0;
`;

const DimensionsContainer = styled.div`
	& p {
		margin: 0;
		padding: 0 0 10px 0;
	}

	@media (max-width: 900px) {
		margin: 0 0 2em 0;
	}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;

	border-top: 1px solid ${secondaryDark};
	padding: 10px 0;

	&:last-of-type {
		padding: 10px 0 0 0;
	}
`;

const ProductLinks = styled.div`
	margin: 0 0 2em 0;
`;

const ActiveSwatchContainer = styled.div`
	// max-height: 500px;

	// & > div {
	// 	max-height: 500px;
	// }
`;

const ActiveProductDetail = styled.img`
	&.landscape {
		object-fit: cover;
	}

	&.portrait {
		object-fit: contain;
	}

	object-position: left;

	width: 100%;
	height: 100%;
`;

const ActiveSwatchText = styled.div`
	margin: 10px 0 0 0;

	min-height: 20px;

	& p {
		margin: 0;
	}
`;

export const AdditionalInformation = ({ data, product }) => {
	const [activeSwatch, setActiveSwatch] = useState(null);

	const materials =
		data.materials !== null
			? data.materials
					.filter((material) => material.material.fluid !== null)
					.map((material, index) => (
						<Swatch
							key={`material_number_${index}`}
							onClick={() => setActiveSwatch(material.product_detail)}
						>
							<AspectRatioImageContainer image={null} padding={`100`}>
								<img
									srcSet={material.material.fluid.srcSetWebp}
									src={material.material.fluid.srcWebp}
									alt={material.material.alt}
									loading="lazy"
								/>
							</AspectRatioImageContainer>
						</Swatch>
					))
			: null;

	const colors =
		data.colours !== null
			? data.colours
					.filter((colour) => colour.colour.fluid !== null)
					.map((colour, index) => (
						<Swatch
							key={`colour_number_${index}`}
							onClick={() => setActiveSwatch(colour.product_detail)}
						>
							<AspectRatioImageContainer image={null} padding={`100`}>
								<img
									srcSet={colour.colour.fluid.srcSetWebp}
									src={colour.colour.fluid.srcWebp}
									alt={colour.colour.alt}
									loading="lazy"
								/>
							</AspectRatioImageContainer>
						</Swatch>
					))
			: null;

	const dimensions = data.dimensions
		.filter((dimension) => dimension.dimensions_text !== null)
		.map((dimension, index) => (
			<Grid key={`dimension_number_${index}`}>
				<div className="grid-col">{dimension.dimensions_title}</div>
				<div className="grid-col">{dimension.dimensions_text}</div>
			</Grid>
		));

	useEffect(() => {
		if (data.materials.length >= 1 && data.materials !== null) {
			setActiveSwatch(data.materials[0].product_detail);
		} else if (data.colours.length >= 1 && data.colours !== null) {
			setActiveSwatch(data.colours[0].product_detail);
		}
	}, [data]);

	const careGuides = data.care_guides
		.filter((guide) => guide.care_guide.document !== null)
		.map((guide, index) => (
			<div key={`single_care_guide_${index}`}>
				<a
					className="caps"
					href={guide.care_guide.document.url}
					target="_blank"
					rel="noreferrer"
				>
					Care Guide
				</a>
			</div>
		));

	return (
		<ContentContainer>
			<FlexContainer
				desktopPadding={`0 60px`}
				tabletPadding={`0 35px`}
				mobilePadding={`0 15px`}
				cv={true}
			>
				<FlexRow width={`auto`}>
					<FlexCol desktopMaxWidth={`33.333%`}>
						<Flex>
							<FlexRow margin={`0`}>
								<FlexCol flex={`100%`} desktopMaxWidth={`100%`}>
									<ProductLinks>
										<div>
											<ProductEnquiry
												product={product}
												form={data.enquiry_form.document}
											/>
										</div>

										{careGuides}

										<div>
											<ShippingPopup />
										</div>

										{data.tech_specs.length > 1 ? (
											<>
												<br />
												<TechSpecs options={data.tech_specs} />
											</>
										) : (
											<a
												href={data.tech_specs[0].tech_specs_pdf.url}
												target="_blank"
												className="caps"
											>
												Tech Specs
											</a>
										)}
									</ProductLinks>
								</FlexCol>
							</FlexRow>

							<FlexRow alignSelf={`flex-end`} margin={`0`}>
								{materials.length >= 1 && (
									<FlexCol
										tabletFlex={`25%`}
										mobileFlex={`50%`}
										mobileOrder={2}
									>
										<SwatchTitle className="caps">
											{data.materials_title}
										</SwatchTitle>
										<FlexRow width={`auto`}>{materials}</FlexRow>
									</FlexCol>
								)}

								{colors.length >= 1 && (
									<FlexCol
										tabletFlex={`25%`}
										mobileFlex={`50%`}
										mobileOrder={3}
									>
										<SwatchTitle className="caps">Colour</SwatchTitle>
										<FlexRow width={`auto`}>{colors}</FlexRow>
									</FlexCol>
								)}

								{dimensions.length >= 1 && (
									<FlexCol tabletFlex={`100%`} flex={`100%`} mobileOrder={1}>
										<DimensionsContainer>
											<p className="caps">Dimensions</p>
											<div>{dimensions}</div>
										</DimensionsContainer>
									</FlexCol>
								)}
							</FlexRow>
						</Flex>
					</FlexCol>

					<FlexCol desktopMaxWidth={`66.666%`}>
						{activeSwatch !== null && (
							<ActiveSwatchContainer>
								<AspectRatioImageContainer
									image={null}
									padding={`66.667`}
									background={`transparent`}
								>
									<ActiveProductDetail
										className={ImageOrientation(activeSwatch)}
										srcSet={activeSwatch.fluid.srcSetWebp}
										src={activeSwatch.fluid.srcWebp}
										alt={activeSwatch.alt}
										loading="lazy"
									/>
								</AspectRatioImageContainer>
								<ActiveSwatchText>
									<p>{activeSwatch.alt}</p>
								</ActiveSwatchText>
							</ActiveSwatchContainer>
						)}
					</FlexCol>
				</FlexRow>
			</FlexContainer>
		</ContentContainer>
	);
};
