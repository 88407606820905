import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Modal from "react-modal";
import styled from "styled-components";

// Styles
import { secondaryDark, secondaryLight } from "../utils/colors";

const ModalContainer = styled.div`
	& .header {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
	}

	& a {
		border-bottom: 1px solid ${secondaryDark};

		&:hover {
			border-bottom: 1px solid ${secondaryLight};
		}
	}

	& .close-icon {
		font-size: 20px;
		color: ${secondaryDark};

		&:hover {
			color: ${secondaryDark};
		}
	}
`;

const OpenModalButton = styled.button`
	color: ${secondaryDark};

	&:hover {
		color: ${secondaryLight};
	}
`;

const ProductEnquiryContainer = styled.div`
	margin: 0;
`;

export const ShippingPopup = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const data = useStaticQuery(graphql`
		{
			prismicShippingPopup {
				data {
					title {
						html
					}
					text {
						html
					}
				}
			}
		}
	`);


	if (data.prismicShippingPopup !== null) {
		return (
			<ProductEnquiryContainer>
				<OpenModalButton
					onClick={() => setIsModalOpen(true)}
					className="product-enquiry caps"
				>
					Shipping
				</OpenModalButton>
				<Modal
					isOpen={isModalOpen}
					onRequestClose={() => setIsModalOpen(false)}
					className="delivery-modal"
					contentLabel="Delivery"
				>
					<ModalContainer>
						<div className="header">
							<div
								className="shipping-disclaimer"
								dangerouslySetInnerHTML={{
									__html: data.prismicShippingPopup.data.title.html,
								}}
							/>
							<button
								onClick={() => setIsModalOpen(false)}
								className="close-icon"
							>
								✕
							</button>
						</div>

						<div
							className="shipping-disclaimer"
							dangerouslySetInnerHTML={{
								__html: data.prismicShippingPopup.data.text.html,
							}}
						/>
					</ModalContainer>
				</Modal>
			</ProductEnquiryContainer>
		);
	} else {
		return null;
	}
};
