import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import styled from "styled-components";

// Mailchimp
import { ProductEnquiryForm } from "../forms/product-enquiry-form";

// Styles
import { secondaryDark, secondaryLight } from "../utils/colors";

const ModalContainer = styled.div`
	& .header {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;

		padding: 0 0 15px 0;

		& h3 {
			width: calc(100% - 30px);

			@media (max-width: 600px) {
				font-size: 18px;
			}
		}
	}

	& .close-icon {
		font-size: 20px;
		color: ${secondaryDark};

		&:hover {
			color: ${secondaryDark};
		}
	}
`;

const OpenModalButton = styled.button`
	color: ${secondaryDark};
	// text-transform: capitalize;

	&:hover {
		color: ${secondaryLight};
	}
`;

const ProductEnquiryContainer = styled.div`
	margin: 0;
`;

export const ProductEnquiry = ({ product, form }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<ProductEnquiryContainer>
			<OpenModalButton
				onClick={() => setIsModalOpen(true)}
				className="product-enquiry caps"
			>
				Product Enquiry
			</OpenModalButton>
			<Modal
				isOpen={isModalOpen}
				onRequestClose={() => setIsModalOpen(false)}
				className="product-enquiry-modal"
				contentLabel="Product Enquiry"
			>
				<ModalContainer>
					<div className="header">
						<h3>Product Enquiry: {product.title}</h3>
						<button
							onClick={() => setIsModalOpen(false)}
							className="close-icon"
						>
							✕
						</button>
					</div>

					<ProductEnquiryForm data={form} title={product.title} />
				</ModalContainer>
			</Modal>
		</ProductEnquiryContainer>
	);
};
