import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";
import Select, { components } from "react-select";
import { useForm, Controller } from "react-hook-form";

// Styles
import { secondaryDark, tertiary } from "../utils/colors";

// Components
import { DropdownIcon } from "../icons/dropdown-icon";

// Utils
import { convertToKebabCase } from "../utils/convert-to-kebab";
import { encodeForm } from "../utils/encode-form";
import { capitalize } from "../utils/capitalize";

const EnquiryFormContainer = styled.div`
	width: 100%;

	& span {
		font-size: 12px;
	}

	& h2 {
		margin: 0 0 25px 0;
	}

	& form {
		& .field {
			& input {
				width: 100%;
				margin: 0 0 20px 0;
				padding: 7px 0;

				border: 0;
				border-bottom: 1px solid ${secondaryDark};
				background: transparent;
			}

			& label.message-title {
				margin: 6px 0;
				position: relative;
				display: block;
			}

			& textarea {
				width: calc(100% - 16px);
				max-width: calc(100% - 16px);
				min-width: calc(100% - 16px);

				min-height: 154px;

				margin: 0 0 16px 0;
				padding: 7px;

				border: 1px solid ${secondaryDark};
				border-radius: 0;
				-webkit-appearance: none;
				background: transparent;
			}
		}

		& .disclaimer {
			margin: 0 0 25px 0;

			max-width: calc(100% - 35px);
		}

		& button {
			border: 1px solid ${secondaryDark};
			padding: 7px;

			transition: 150ms all ease;

			&:hover {
				color: ${tertiary};
				background-color: ${secondaryDark};
			}
		}

		& .gdpr-container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: flex-start;

			& .error-message {
				width: 100%;
				margin: 0 0 10px 0;
			}

			& .flex {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: flex-start;
				justify-content: space-between;

				width: 100%;
			}

			& p {
				margin: 0;
			}

			& .gdpr-checkbox {
				width: 16px;
				height: 16px;

				position: relative;

				margin: 0 10px 0 0;
			}
			& .gdpr-checkbox label {
				width: 14px;
				height: 14px;
				cursor: pointer;
				position: absolute;
				top: 0;
				left: 0;
				border: 1px solid ${secondaryDark};
			}
			& .gdpr-checkbox label:after {
				content: "";
				width: 9px;
				height: 5px;
				position: absolute;
				top: 2px;
				left: 2px;
				border: 1px solid ${secondaryDark};
				border-top: none;
				border-right: none;
				background: transparent;
				opacity: 0;
				-webkit-transform: rotate(-45deg);
				transform: rotate(-45deg);
			}
			& .gdpr-checkbox label:hover::after {
				opacity: 1;
			}
			& .gdpr-checkbox input[type="checkbox"] {
				width: 16px;
				height: 16px;

				visibility: hidden;
			}
			& .gdpr-checkbox input[type="checkbox"]:checked + label:after {
				opacity: 1;
			}
		}
	}
`;

const OptionsContainer = styled.div`
	width: 100%;
	margin: 0;

	& .enquiry-options__control {
		border: 0;
		// 1px solid ${secondaryDark};
		border-radius: 0;
		background-color: transparent;
		box-shadow: none;
		padding: 7px 0;

		&:hover {
			border-color: inherit;
		}
	}

	& .enquiry-options__single-value {
		color: inherit;
	}

	& .enquiry-options__single-value {
		margin-left: 0;
	}

	& .enquiry-options__value-container {
		padding: 0;
	}

	& .css-tlfecz-indicatorContainer {
		color: inherit;
		padding: 0;
	}

	& .enquiry-options__indicator {
		padding: 0;
	}

	& .enquiry-options__placeholder {
		color: inherit;
		padding-left: 0;

		margin-left: 0;
		margin-right: 0;
	}

	& .enquiry-options__indicator-separator {
		display: none;
	}

	& .enquiry-options__menu {
		border-radius: 0;

		& .enquiry-options__menu-list {
			padding: 0;
		}

		& .enquiry-options__option--is-focused {
			background-color: #f5f5f5;
		}

		& .enquiry-options__option--is-selected {
			background-color: ${tertiary};
			color: inherit;
		}
	}

	& .css-b8ldur-Input {
		margin: 0;
	}
`;

const ThankYouMessage = styled.div`
	font-size: 16px;
	line-height: 125%;

	& p {
		margin: 0 0 10px 0;
	}
`;

export const ProductEnquiryForm = ({ data, title }) => {
	const [checked, setChecked] = useState(true);
	const [formMessage, setFormMessage] = useState(null);

	// Form Setup
	const {
		control,
		register,
		handleSubmit,
		setValue,
		watch,
		errors,
		reset,
	} = useForm();

	const DropdownIndicator = (props) => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<DropdownIcon isOpen={props.selectProps.menuIsOpen} />
				</components.DropdownIndicator>
			)
		);
	};

	const dropdownOptions = data.data.form_options.map((value) => {
		return (value = {
			value: convertToKebabCase(value.option),
			label: value.option,
			email: value.linked_email_address,
		});
	});

	const additionalFormFields =
		data.data.additional_form_fields.length >= 1
			? data.data.additional_form_fields.map((field, index) => (
					<div
						className="field"
						key={`additional_form_field_${data.id}_${index}`}
					>
						<input
							name={convertToKebabCase(field.additional_form_field)}
							type="text"
							placeholder={field.additional_form_field}
							ref={register}
						/>
					</div>
			  ))
			: null;

	const [enquiryFormTitle, setEnquiryFormTitle] = useState(
		dropdownOptions[0].email
	);
	const watchDropdownOptions = watch("type", enquiryFormTitle);

	useEffect(() => {
		if (
			watchDropdownOptions !== "" &&
			watchDropdownOptions.email !== undefined
		) {
			setEnquiryFormTitle(watchDropdownOptions.email);
		}
	}, [watchDropdownOptions]);

	const onSubmit = (data) => {
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encodeForm({
				"form-name": `${enquiryFormTitle}-enquiry`,
				subject: `${capitalize(enquiryFormTitle)} Enquiry`,
				...data,
			}),
		})
			.then(() => {
				setFormMessage(
					"Thank you for submitting your enquiry. We'll be in touch soon."
				);
				reset();
			})
			.catch((error) => {
				console.log(error);
				setFormMessage(
					"An error occurred while submitting your enquiry. Please email us directly at: info@christian-watson.com"
				);
			});
	};

	const hiddenNetlifyForms = data.data.form_options.map((value, index) => (
		<form
			name={`${value.linked_email_address}-enquiry`}
			data-netlify="true"
			netlify-honeypot="bot-field"
			hidden
			key={`hidden_form_${data.id}_${index}`}
		>
			<input
				type="hidden"
				name="form-name"
				value={`${value.linked_email_address}-enquiry`}
			/>
			<input
				type="hidden"
				name="subject"
				value={`${capitalize(value.linked_email_address)} Enquiry`}
			/>
			<input type="text" name="name" />
			<input type="email" name="email" />
			<input type="tel" name="telephone" />

			{data.data.additional_form_fields.map((field, index) => (
				<div
					className="field"
					key={`additional_hidden_form_fields_${data.id}_${index}`}
				>
					<input
						type="text"
						name={convertToKebabCase(field.additional_form_field)}
					/>
				</div>
			))}

			<textarea
				name="message"
				placeholder={data.data.form_message_placeholder}
			/>
		</form>
	));

	return (
		<EnquiryFormContainer>
			{formMessage === null ? (
				<form
					name={`${enquiryFormTitle}-enquiry`}
					method="POST"
					onSubmit={handleSubmit(onSubmit)}
					data-netlify="true"
					data-netlify-honeypot="bot-field"
				>
					<Row justifyContent="center">
						<Col col={12} md={6}>
							<div className="field">
								<input type="hidden" name="bot-field" />
								<input
									type="hidden"
									name="form-name"
									name={`${enquiryFormTitle}-enquiry`}
								/>
								<input
									type="hidden"
									name="subject"
									value={`${capitalize(enquiryFormTitle)} Enquiry`}
								/>
								{/* errors will return when field validation fails  */}
								{errors.name && <span>This field is required</span>}
								<input
									name="name"
									type="text"
									placeholder="Name"
									autoComplete="name"
									ref={register({ required: true })}
								/>
							</div>

							<div className="field">
								{/* errors will return when field validation fails  */}
								{errors.email && <span>This field is required</span>}
								<input
									name="email"
									type="email"
									placeholder="Email"
									autoComplete="email"
									ref={register({ required: true })}
								/>
							</div>
							<div className="field">
								<input
									name="telephone"
									type="tel"
									placeholder="Telephone"
									autoComplete="telephone"
									ref={register}
								/>
							</div>

							{additionalFormFields}

							<OptionsContainer>
								{/* errors will return when field validation fails  */}
								{errors.type && <span>This field is required</span>}
								<Controller
									name="type"
									as={Select}
									options={dropdownOptions}
									placeholder={data.data.question}
									classNamePrefix="enquiry-options"
									components={{ DropdownIndicator }}
									isSearchable={false}
									control={control}
									rules={{ required: true }}
									defaultValue=""
								/>
							</OptionsContainer>
						</Col>

						<Col col={12} md={6}>
							<div className="field">
								<label className="message-title">Message</label>
								{/* errors will return when field validation fails  */}
								{errors.message && <span>This field is required</span>}
								<textarea
									name="message"
									rows="10"
									ref={register({ required: true })}
									placeholder={data.data.form_message_placeholder}
								/>
							</div>

							<div className="gdpr-container">
								{/* errors will return when field validation fails  */}
								{errors.gdprcheck && (
									<div className="error-message">
										<span>This field is required</span>
									</div>
								)}

								<div className="flex">
									<div
										className="gdpr-checkbox"
										onClick={() => setChecked(!checked)}
									>
										<input
											type="checkbox"
											id="gdpr-checkbox"
											name="gdprcheck"
											value={checked}
											checked={checked}
											onChange={(e) => {
												setChecked(e.target.checked);
											}}
											ref={register({
												required: true,
											})}
										/>
										<label htmlFor="gdprcheck"></label>
									</div>
									<div
										className="disclaimer small-text"
										dangerouslySetInnerHTML={{
											__html: data.data.form_disclaimer_text.html,
										}}
									/>
								</div>
							</div>

							<button type="submit" className="">
								Submit
							</button>
						</Col>
					</Row>
				</form>
			) : (
				<ThankYouMessage>
					<p>{formMessage}</p>
				</ThankYouMessage>
			)}

			{hiddenNetlifyForms}
		</EnquiryFormContainer>
	);
};
