import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";

// Components
import { ContentZoom } from "./content-zoom";

const GalleryContainer = styled.div`
	position: relative;

	width: 100%;
	height: 100%;
	max-height: 100%;

	padding: 0;

	& .carousel.carousel-slider,
	& .slider-wrapper,
	& .lightbox-gallery,
	& .slider,
	& .slide,
	& video,
	& img {
		height: 100%;
		max-height: 100%;
	}

	& .carousel.carousel-slider .control-arrow {
		padding: 0;
		width: 100px;
	}
`;

export const LightboxGallery = ({ title, images }) => {
	const [currentSlide, setCurrentSlide] = useState(0);

	const updateCurrentSlide = (index) => {
		if (currentSlide !== index) {
			setCurrentSlide(index);
		}
	};

	const galleryContent = images
		.filter(
			(image) => image.originalSrc !== null && image.originalSrc !== undefined
		)
		.map((image, index) => (
			<ContentZoom
				largeImageUrl={image.originalSrc}
				altText={title}
				key={`project_images_${index}`}
			/>
		));

	useEffect(() => {
		if (typeof window !== undefined) {
			document.querySelector(".lightbox-gallery").focus();
		}
	}, [galleryContent]);

	return (
		<GalleryContainer>
			<Carousel
				showThumbs={false}
				showIndicators={false}
				showStatus={false}
				infiniteLoop={true}
				useKeyboardArrows={true}
				className="lightbox-gallery"
				selectedItem={currentSlide}
				onChange={(index) => updateCurrentSlide(index)}
				autoPlay={false}
				stopOnHover={false}
				interval={8000}
				swipeable={true}
			>
				{galleryContent}
			</Carousel>
		</GalleryContainer>
	);
};
