import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";

const GalleryContainer = styled.div`
	position: relative;

	width: 100%;
	height: 100%;
	max-height: 100%;

	padding: 0;

	& .carousel.carousel-slider,
	& .slider-wrapper,
	& .image-gallery,
	& .slider,
	& .slide,
	& video,
	& img {
		height: 100%;
		max-height: 100%;
	}

	& .carousel.carousel-slider .control-arrow {
		padding: 0;
		width: 100px;
	}
`;

const ImageContainer = styled.div`
	position: relative;

	width: 100%;
	height: 100%;
	max-height: calc(100vh - 200px);

	overflow: hidden;
	cursor: zoom-in;

	& img {
		object-fit: cover;
		// object-fit: contain;
		object-position: bottom;

		max-height: calc(100vh - 200px);
	}
`;

const FourThree = styled.div`
	position: relative;
	max-height: calc(100vh - 200px);

	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: 75%;
	}

	& > .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
`;

const ThreeTwo = styled.div`
	position: relative;

	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: 66.667%;
	}

	& > .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
`;

const Caption = styled.div`
	position: relative;

	width: 100%;

	margin: 15px 0 0 0;

	font-feature-settings: "tnum";
`;

export const GalleryProduct = ({
	title,
	images,
	setLightBoxOpen,
	isMobile,
}) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [allGalleryContent, setAllGalleryContent] = useState([]);
	const [totalSlides, setTotalSlides] = useState(null);

	useEffect(() => {
		setTotalSlides(images.length);
		setAllGalleryContent(images);
	}, []);

	const updateCurrentSlide = (index) => {
		if (currentSlide !== index) {
			setCurrentSlide(index);
		}
	};

	const galleryContent = images
		.filter(
			(image) => image.originalSrc !== null && image.originalSrc !== undefined
		)
		.map((image, index) => (
			<ImageContainer
				key={`project_images_${index}`}
				onClick={() => {
					if (!isMobile) {
						setLightBoxOpen(true);
					} else {
						return null;
					}
				}}
			>
				<FourThree>
					<div className="content">
						<img
							src={image.originalSrc}
							alt={title}
							loading={index <= 1 ? `eager` : `lazy`}
						/>
					</div>
				</FourThree>
			</ImageContainer>
		));

	return (
		<GalleryContainer>
			{galleryContent.length > 1 ? (
				<>
					<Carousel
						showThumbs={false}
						showIndicators={false}
						showStatus={false}
						infiniteLoop={true}
						useKeyboardArrows={true}
						className="image-gallery"
						selectedItem={currentSlide}
						onChange={(index) => updateCurrentSlide(index)}
						autoPlay={isMobile ? false : true}
						stopOnHover={false}
						interval={8000}
						swipeable={true}
					>
						{galleryContent}
					</Carousel>

					<Caption>
						{currentSlide + 1}/{totalSlides}
					</Caption>
				</>
			) : (
				<>{galleryContent}</>
			)}
		</GalleryContainer>
	);
};
