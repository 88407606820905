import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { secondaryLight, secondaryDark } from "../utils/colors";

const CollectionTitle = styled.h2`
	color: ${secondaryLight};

	& a:hover {
		color: ${secondaryDark};
	}
`;

export const CollectionInformation = ({ collection }) => (
	<CollectionTitle className="small-text caps">
		<Link to={collection.url}>{collection.document.data.title.text}</Link>
	</CollectionTitle>
);
