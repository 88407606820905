import React, { useContext, useState, useEffect, useCallback } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";

// Containers
import { ContentContainer } from "../components/containers/container";

// Context
import StoreContext from "../components/context/store";

// Components
import { FlexContainer } from "../components/containers/flex-container";
import { ProductInfo } from "../components/product/product-info";
import { VariantSelector } from "../components/product/variant-selectors";
import { QuantityButton } from "../components/product/quantity-button";
import { ModularProductContent } from "../components/product/modular-product-content";
import { GalleryProduct } from "../components/images/gallery-product";
import { Lightbox } from "../components/images/lightbox";
import { AdditionalInformation } from "../components/product/additional-information";

// Styles
import { secondaryLight, secondaryDark } from "../components/utils/colors";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

const Page = styled.div`
	margin: 150px 0 0 0;

	@media (max-width: 900px) {
		margin: 100px 0 0 0;
	}
`;

// Product
const ProductText = styled.div`
	width: 100%;
	height: 100%;

	min-width: 370px;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	& > div {
		width: 100%;

		@media (max-width: 900px) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

	& p {
		& u {
			text-decoration: none;
			border-bottom: 1px solid ${secondaryDark};

			&:hover {
				border-bottom: 1px solid ${secondaryLight};
			}
		}
	}

	@media (max-width: 768px) {
		min-width: 100%;
	}
`;

const Text = styled.div`
	max-width: 600px;
	order: 12;

	@media (max-width: 900px) {
		order: 1;
		margin: 25px 0;
	}
`;

const ProductImageContainer = styled.div`
	width: 100%;
	margin: 3px 0 0 0;
`;

const Buttons = styled.div`
	align-self: flex-end;

	& .add-to-cart-button-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		width: 100%;

		@media (max-width: 900px) {
			margin: 25px 0 0 0;
		}

		@media (max-width: 768px) {
			align-items: center;
		}
	}
`;

const ProductButton = styled.button`
	transition: 150ms color ease;

	color: ${secondaryDark};

	&:hover {
		color: ${secondaryLight};
	}

	@media (max-width: 768px) {
		background-color: ${secondaryDark};
		color: #fff;

		border-radius: 3px;
		padding: 5px 8px;

		&:hover {
			color: #fff;
		}
	}
`;

const SoldOutButton = styled.button`
	color: ${secondaryLight};
`;

const ProductOptions = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	order: 2;

	@media (max-width: 900px) {
		order: 12;
	}
`;

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	margin: 0 -15px;
`;

const FlexCol = styled.div`
	flex: 1;
	max-width: ${(props) => props.desktopMaxWidth};

	padding: 0 15px;

	@media (max-width: 900px) {
		flex: 100%;
		width: 100%;
		max-width: 100%;
	}
`;

const Product = ({ data, location }) => {
	if (data.shopifyProduct === null) {
		return (
			<ContentContainer>
				<FlexContainer
					desktopPadding={`0 60px`}
					tabletPadding={`0 35px`}
					mobilePadding={`0 15px`}
					cv={true}
				>
					<FlexRow>
						<FlexCol desktopMaxWidth={`calc(100% - 400px)`}>
							<h1>Product not found</h1>
						</FlexCol>
					</FlexRow>
				</FlexContainer>
			</ContentContainer>
		);
	} else {
		return <ProductContent data={data} location={location} />;
	}
};

const ProductContent = ({ data, location }) => {
	const product = data.shopifyProduct;

	// Media Query
	let isMobile = useMediaQuery("(hover: none) and (pointer: coarse)");

	const productPageContent =
		data.prismicProduct !== null ? data.prismicProduct : null;

	const [quantity, setQuantity] = useState(1);
	const [variant, setVariant] = useState(product.variants[0]);

	const context = useContext(StoreContext);
	const productVariant =
		context.client.product.helpers.variantForOptions(product, variant) ||
		variant;

	const [available, setAvailable] = useState(productVariant.availableForSale);

	const [isLightboxOpen, setLightBoxOpen] = useState(false);

	const [addToCartButtonMessage, setAddToCartButtonMessage] = useState(
		"Add To Basket"
	);

	useEffect(() => {
		let defaultOptionValues = {};
		product.options.forEach((selector) => {
			defaultOptionValues[selector.name] = selector.values[0];
		});
		setVariant(defaultOptionValues);
	}, []);

	const checkAvailability = useCallback(
		(productId) => {
			context.client.product.fetch(productId).then(() => {
				// this checks the currently selected variant for availability
				const result = product.variants.filter(
					(variant) => variant.shopifyId === productVariant.shopifyId
				);

				setAvailable(result[0].availableForSale);
			});
		},
		[context.client.product, productVariant.shopifyId, product.variants]
	);

	useEffect(() => {
		checkAvailability(product.shopifyId);
	}, [productVariant, checkAvailability, product.shopifyId]);

	const handleAddToCart = () => {
		context.addVariantToCart(productVariant.shopifyId, quantity);

		setAddToCartButtonMessage("Added To Basket");

		const timer = setTimeout(() => {
			setAddToCartButtonMessage("Add To Basket");
		}, 2000);
		return () => clearTimeout(timer);
	};

	const handleOptionChange = (event) => {
		setVariant((prevState) => ({
			...prevState,
			[event.option]: event.value,
		}));
	};

	const schemaImages = product.images.map((image) => image.originalSrc);
	const productSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		name: `${product.title}`,
		image:
			data.prismicProduct.data.thumbnail.fluid !== null
				? data.prismicProduct.data.thumbnail.fluid.src
				: schemaImages,
		description: `${product.description}`,
		sku: `${product.variants[0].sku}`,
		brand: {
			"@type": "Brand",
			name: "Christian Watson",
		},
		offers: {
			"@type": "Offer",
			url: `https://christian-watson.com/products/${product.handle}`,
			priceCurrency: "GBP",
			price: `${product.variants[0].price}`,
		},
	};

	return (
		<Page>
			<Helmet
				title={`${
					data.prismicProduct.data.seo_title !== null
						? data.prismicProduct.data.seo_title
						: data.prismicProduct.data.title.text
				} | Christian Watson`}
				meta={[
					{
						name: "og:title",
						content: `${
							data.prismicProduct.data.seo_title !== null
								? data.prismicProduct.data.seo_title
								: data.prismicProduct.data.title.text
						} | Christian Watson`,
					},
					{
						name: "og:image",
						content: `${
							data.prismicProduct.data.thumbnail.fluid !== null
								? data.prismicProduct.data.thumbnail.fluid.src
								: ``
						}`,
					},
					{
						name: "og:image:secure_url",
						content: `${
							data.prismicProduct.data.thumbnail.fluid !== null
								? data.prismicProduct.data.thumbnail.fluid.src
								: ``
						}`,
					},
					{
						name: "og:image:width",
						content: `1200`,
					},
					{
						name: "og:image:height",
						content: `630`,
					},
					{
						name: "og:locale",
						content: `en`,
					},
					{
						name: "description",
						content: `${
							data.prismicProduct.data.seo_description !== null
								? data.prismicProduct.data.seo_description
								: data.prismicProduct.data.title.text
						} | Christian Watson
						`,
					},
					{
						name: "og:description",
						content: `${
							data.prismicProduct.data.seo_description !== null
								? data.prismicProduct.data.seo_description
								: data.prismicProduct.data.title.text
						} | Christian Watson
						`,
					},
					{
						name: "twitter:title",
						content: `${
							data.prismicProduct.data.seo_title !== null
								? data.prismicProduct.data.seo_title
								: data.prismicProduct.data.title.text
						} | Christian Watson`,
					},
					{
						name: "twitter:description",
						content: `${
							data.prismicProduct.data.seo_description !== null
								? data.prismicProduct.data.seo_description
								: data.prismicProduct.data.title.text
						} | Christian Watson
						`,
					},
					{
						name: "twitter:card",
						content: `summary_large_image`,
					},
					{
						name: "twitter:image",
						content: `${
							data.prismicProduct.data.thumbnail.fluid !== null
								? data.prismicProduct.data.thumbnail.fluid.src
								: ``
						}`,
					},
				]}
			/>

			<Helmet encodeSpecialCharacters={false}>
				<script type="application/ld+json">
					{JSON.stringify(productSchema)}
				</script>
			</Helmet>

			<ContentContainer>
				<FlexContainer
					desktopPadding={`0 60px`}
					tabletPadding={`0 35px`}
					mobilePadding={`0 15px`}
					cv={true}
				>
					<FlexRow>
						<FlexCol desktopMaxWidth={`calc(100% - 400px)`}>
							<ProductInfo
								product={product}
								collection={data.prismicProduct.data.collection}
								location={location}
								showOnDesktop={false}
								showOnMobile={true}
								price={productVariant.price}
							/>
							<ProductImageContainer>
								<GalleryProduct
									images={product.images}
									setLightBoxOpen={setLightBoxOpen}
									title={product.title}
									isMobile={isMobile}
								/>
							</ProductImageContainer>
						</FlexCol>

						<FlexCol desktopMaxWidth={`400px`}>
							<ProductText>
								<div>
									<ProductInfo
										product={product}
										collection={data.prismicProduct.data.collection}
										location={location}
										showOnDesktop={true}
										showOnMobile={false}
										price={productVariant.price}
									/>

									{product.options[0].name !== "Title" && (
										<ProductOptions>
											{product.options.map((options) => (
												<VariantSelector
													onChange={handleOptionChange}
													options={options}
													activeVariant={productVariant}
													key={`single_product_variation_option_${options.name}`}
												/>
											))}
										</ProductOptions>
									)}

									<Text
										dangerouslySetInnerHTML={{
											__html: product.descriptionHtml,
										}}
									/>
								</div>

								<Buttons>
									{available === true && (
										<div className="add-to-cart-button-container">
											<ProductButton
												type="submit"
												disabled={available === false}
												onClick={handleAddToCart}
												className="add-to-cart"
											>
												{addToCartButtonMessage}
											</ProductButton>

											<QuantityButton
												quantity={quantity}
												setQuantity={setQuantity}
											/>
										</div>
									)}

									{available === false && (
										<SoldOutButton
											type="submit"
											disabled={true}
											className="add-to-cart"
										>
											Sold Out
										</SoldOutButton>
									)}
								</Buttons>
							</ProductText>
						</FlexCol>
					</FlexRow>
				</FlexContainer>
			</ContentContainer>

			{productPageContent !== null && productPageContent !== undefined && (
				<AdditionalInformation
					data={productPageContent.data}
					product={product}
				/>
			)}

			{productPageContent !== null && (
				<ModularProductContent
					data={productPageContent.data.body}
					pageType={`product`}
				/>
			)}

			<Lightbox
				images={product.images}
				isLightboxOpen={isLightboxOpen}
				setLightBoxOpen={setLightBoxOpen}
				title={product.title}
			/>
		</Page>
	);
};

export default Product;

export const query = graphql`
	query($uid: String!, $shopifyProduct: String!) {
		prismicProduct(uid: { eq: $uid }) {
			data {
				seo_title
				seo_description
				thumbnail {
					alt
					fluid(imgixParams: { w: 1200, h: 630 }) {
						src
					}
				}
				collection {
					url
					document {
						... on PrismicCollection {
							id
							data {
								title {
									text
								}
							}
						}
					}
				}
				body {
					... on PrismicProductBodyImage {
						id
						slice_type
						primary {
							image {
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
								dimensions {
									height
									width
								}
							}
							image_position
							image_size
							link {
								url
								type
							}
						}
					}
					... on PrismicProductBodyVideo {
						id
						slice_type
						primary {
							video {
								width
								height
								html
								embed_url
							}
							link {
								url
								type
							}
							video_position
							video_size
						}
					}
					... on PrismicProductBodyImageBanner {
						id
						slice_type
						primary {
							image {
								fluid(
									imgixParams: { ar: "16:3", fit: "clamp", crop: "entropy" }
								) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
								dimensions {
									height
									width
								}
							}
							link {
								url
								type
							}
						}
					}
					... on PrismicProductBodyVideoBanner {
						id
						slice_type
						primary {
							link {
								url
								type
							}
							video {
								width
								height
								html
								embed_url
								title
							}
						}
					}
					... on PrismicProductBodyText {
						id
						slice_type
						primary {
							text {
								html
							}
							text_width
							text_columns
							text_position
						}
					}
					... on PrismicProductBodyCollections {
						id
						slice_type
						items {
							collection {
								document {
									... on PrismicCollection {
										id
										url
										prismicId
										data {
											thumbnail {
												fluid {
													srcSetWebp
													srcWebp
													aspectRatio
												}
												alt
											}
											title {
												text
											}
										}
									}
								}
							}
						}
						primary {
							collections_title {
								html
							}
						}
					}
					... on PrismicProductBodyMailchimp {
						id
						slice_type
						primary {
							mailchimp_form {
								document {
									... on PrismicMailchimp {
										id
										prismicId
										data {
											call_to_action_text
											privacy_policy {
												html
											}
											text {
												html
											}
										}
									}
								}
							}
						}
					}
					... on PrismicProductBodyLimitedEdition {
						id
						slice_type
						primary {
							limited_edition_title {
								html
							}
						}
						items {
							product {
								url
								document {
									... on PrismicProduct {
										id
										data {
											linked_product
										}
									}
								}
							}
							image {
								alt
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								dimensions {
									width
									height
								}
							}
						}
					}
					... on PrismicProductBodyImageWithText {
						id
						slice_type
						primary {
							image_with_text {
								html
							}
							image_with_text_image {
								dimensions {
									width
									height
								}
								fluid {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
							image_with_text_title {
								html
							}
							text_position
							text_vertical_position
						}
					}
					... on PrismicProductBodyFaq {
						id
						slice_type
						primary {
							faq_section_title {
								html
							}
						}
						items {
							faq_title {
								text
							}
							faq_text {
								html
							}
						}
					}
					... on PrismicProductBodySlideshow {
						id
						slice_type
						primary {
							slideshow_title {
								html
							}
						}
						items {
							image {
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
							text {
								html
							}
						}
					}
					... on PrismicProductBodySlideshowWithText {
						id
						slice_type
						primary {
							slideshow_with_text_title {
								html
							}
							slideshow_with_text {
								html
							}
						}
						items {
							image_title {
								html
								text
							}
							image_text {
								html
								text
							}
							image {
								fluid(imgixParams: { ar: "3:2", fit: "crop" }) {
									srcSetWebp
									srcWebp
									aspectRatio
								}
								alt
							}
						}
					}
					... on PrismicProductBodyRelatedProducts {
						id
						slice_type
						items {
							product {
								url
								document {
									... on PrismicProduct {
										id
										data {
											linked_product
											thumbnail {
												alt
												fluid {
													srcWebp
													srcSetWebp
												}
											}
										}
									}
								}
							}
						}
					}
				}
				cad_files {
					cad_file {
						url
					}
				}
				tech_specs {
					download_title
					tech_specs_pdf {
						url
					}
				}
				care_guides {
					care_guide {
						document {
							... on PrismicCareGuide {
								id
								url
								data {
									title {
										text
									}
								}
							}
						}
					}
				}
				product_images {
					product_image_file {
						url
					}
				}
				colours {
					colour {
						fluid {
							srcWebp
							srcSetWebp
							aspectRatio
						}
						alt
					}
					product_detail {
						dimensions {
							width
							height
						}
						fluid(srcSetBreakpoints: [200, 340, 500]) {
							srcSetWebp
							srcWebp
							aspectRatio
						}
						alt
					}
				}
				dimensions {
					dimensions_title
					dimensions_text
				}
				materials_title
				materials {
					material {
						fluid {
							srcWebp
							srcSetWebp
							aspectRatio
						}
						alt
					}
					product_detail {
						dimensions {
							width
							height
						}
						fluid {
							srcSetWebp
							srcWebp
							aspectRatio
						}
						alt
					}
				}
				enquiry_form {
					document {
						... on PrismicProductEnquiryForm {
							id
							data {
								question
								form_options {
									option
									linked_email_address
								}
								form_message_placeholder
								additional_form_fields {
									additional_form_field
								}
								form_disclaimer_text {
									html
								}
							}
						}
					}
				}
			}
		}
		shopifyProduct(handle: { eq: $shopifyProduct }) {
			handle
			id
			title
			productType
			descriptionHtml
			description
			shopifyId
			availableForSale
			priceRange {
				minVariantPrice {
					amount
				}
				maxVariantPrice {
					amount
				}
			}
			options {
				id
				name
				values
			}
			variants {
				id
				title
				price
				availableForSale
				shopifyId
				selectedOptions {
					name
					value
				}
			}
			images {
				originalSrc
				id
			}
		}
	}
`;
