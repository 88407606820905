import React, { useEffect, useContext } from "react";

// Context
import { FooterColor } from "../context/footer-color";

// Components
import { DetermineModuleToDisplay } from "../utils/determine-module-to-display";
import { ContentContainer } from "../containers/container";
import { DetermineFooterColor } from "../utils/determine-footer-color";

export const ModularProductContent = ({ data, pageType }) => {
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		const calcFooterColor = DetermineFooterColor(
			data[data.length - 1].slice_type
		);

		setFooterColor(calcFooterColor);
	}, []);

	const content = data
		.filter((content) => content !== null)
		.map((content, outerIndex) => {
			let previousContentSliceType =
				outerIndex !== 0 ? data[outerIndex - 1].slice_type : false;

			return (
				<ContentContainer
					key={`product_module_wrapper_${outerIndex}`}
					marginOverride={
						(content.slice_type === "enquiry_form" ||
							content.slice_type === "mailchimp") &&
						(previousContentSliceType === "video_-_banner" ||
							previousContentSliceType === "image_-_banner" ||
							previousContentSliceType === "related_products")
					}
				>
					<DetermineModuleToDisplay content={content} pageType={pageType} />
				</ContentContainer>
			);
		});

	return <>{content}</>;
};
