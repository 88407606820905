import React from "react";
import styled from "styled-components";
import Select, { components } from "react-select";

// Styles
import { secondaryDark } from "../utils/colors";

// Components
import { DropdownIcon } from "../icons/dropdown-icon";

const VariantOptions = styled.div`
	max-width: 180px;
	width: 145px;

	margin: 0 15px 0 0;

	cursor: pointer;
`;

const Option = styled.button`
	width: auto;
	margin: 0 20px 5px 0;

	border-bottom: 1px solid
		${(props) => (props.activeVariant ? "black" : "transparent")};

	@media (max-width: 1024px) {
		margin: 0 30px 15px 0;
	}
`;

const OptionsContainer = styled.div`
	& .product-options__control {
		border-color: inherit;
		border: 0;
		border-radius: 0;
		background-color: transparent;
		box-shadow: none;

		&:hover {
			border-color: inherit;
		}
	}

	& .product-options__menu-list {
		padding: 0;
	}

	& .product-options__single-value {
		color: inherit;
	}

	& .product-options__single-value {
		margin-left: 0;
	}

	& .product-options__value-container {
		padding: 0;
	}

	& .css-tlfecz-indicatorContainer {
		color: inherit;
		padding: 0;
	}

	& .product-options__indicator {
		padding: 0;
	}

	& .product-options__placeholder {
		color: inherit;
		padding-left: 0;

		margin-left: 0;
		margin-right: 0;
	}

	& .product-options__indicator-separator {
		display: none;
	}

	& .product-options__menu {
		border-radius: 0;
		margin-top: 4px;

		& .product-options__option--is-focused {
			background-color: #fff;
		}

		& .product-options__option--is-selected {
			background-color: ${secondaryDark};
			color: #fff;
		}

		&:hover .product-options__option--is-selected {
			background-color: #fff;
			color: inherit;
		}

		& .product-options__option {
			cursor: pointer;

			&:hover {
				background-color: ${secondaryDark};
				color: #fff;
			}
		}
	}

	& .css-b8ldur-Input {
		margin: 0;
	}
`;

export const VariantSelector = ({ onChange, options, activeVariant }) => {
	const DropdownIndicator = (props) => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<DropdownIcon isOpen={props.selectProps.menuIsOpen} />
				</components.DropdownIndicator>
			)
		);
	};

	const dropdownOptions = options.values.map((value) => {
		return (value = {
			option: options.name,
			value: value,
			label: value,
		});
	});

	return (
		<VariantOptions>
			<OptionsContainer>
				<Select
					options={dropdownOptions}
					placeholder={dropdownOptions[0].label}
					classNamePrefix="product-options"
					onChange={(event) => onChange(event)}
					components={{ DropdownIndicator }}
					name={dropdownOptions.name}
					isSearchable={false}
				/>
			</OptionsContainer>
		</VariantOptions>
	);
};
